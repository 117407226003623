import { useState } from 'react'
import { Select, Modal, Form, Input, Row } from 'antd';
import emailjs from 'emailjs-com'
import { Link } from "react-router-dom";

const { Option } = Select;

let form = '';

export const Contact = (props) => {
  const Spinoff = props.Spinoff ? true : false
  const [ name, setNameState] = useState('')
  const [ email, setEmailState] = useState('');
  const [ phone, setPhoneState] = useState('');
  const [ jobTitle, setJobTitleState] = useState('');
  const [ companyName, setCompanyNameState] = useState('');
  const [ message, setMessageState] = useState('');
  const [ organizationFields, setSelect1State] = useState('');
  const [ servicesLookingFor, setSelect2State] = useState('');
  const [ showModel, setShowModel ] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    if(name === 'name'){
      setNameState(value)
    }
    if(name === 'email'){
      setEmailState(value)
    }
    if(name === 'phone'){
      setPhoneState(value)
    }
    if(name === 'message'){
      setMessageState(value)
    }
    if(name === 'jobTitle'){
      setJobTitleState(value)
    }
    if(name === 'companyName'){
      setCompanyNameState(value)
    }
  }

  const handleSubmit = () => {
    let sentMessage = {
      name:name,
      email: email,
      phone: phone,
      jobTitle: jobTitle,
      companyName: companyName,
      organizationFields: organizationFields,
      servicesLookingFor: servicesLookingFor,
      message: message
    }
    
    emailjs
      .send(
        'service_kg2itzb', 'template_byqn4oy', sentMessage, 'user_MwrM5y1qKue7LHBrOMGmM'
      )
      .then(
        (result) => {
          console.log(result.text)
          setShowModel(true)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  const select1 = [
      <Option key = 'Device manufactures'>
        <div style={{display: "flex"}}>
          <div className='circle1'>
            <div className='circle'/>
          </div>
          <span>Device manufactures</span>
        </div>
      </Option>, 
      <Option key = 'RPM providers'>
        <div style={{display: "flex"}}>
          <div className='circle1'>
            <div className='circle'/>
          </div>
          <span>RPM providers</span>
        </div>
      </Option>, 
      <Option key = 'Telehealth solutions'>
        <div style={{display: "flex"}}>
          <div className='circle1'>
            <div className='circle'/>
          </div>
          <span>Telehealth solutions</span>
        </div>
      </Option>, 
      <Option key = 'Health providers'>
        <div style={{display: "flex"}}>
          <div className='circle1'>
            <div className='circle'/>
          </div>
          <span>Health providers</span>
        </div>
      </Option>, 
      <Option key = 'Others'>
        <div style={{display: "flex"}}>
          <div className='circle1'>
            <div className='circle'/>
          </div>
          <span>Others</span>
        </div>
      </Option>, 
  ]

  const select2 = [
          <Option key = 'Device requirement'>
            <div style={{display: "flex"}}>
              <div className='circle1 redg1'>
                <div className='circle redg'/>
              </div>
              <span>Device requirement</span>
            </div>
          </Option>, 
          <Option key = 'Design your own devices'>
            <div style={{display: "flex"}}>
              <div className='circle1 redg1'>
                <div className='circle redg'/>
              </div>
              <span>Design your own devices</span>
            </div>
          </Option>, 
          <Option key = 'Connectivity solutions'>
            <div style={{display: "flex"}}>
              <div className='circle1 redg1'>
                <div className='circle redg'/>
              </div>
              <span>Connectivity solutions</span>
            </div>
          </Option>, 
          <Option key = 'Connect your devices'>
            <div style={{display: "flex"}}>
              <div className='circle1 redg1'>
                <div className='circle redg'/>
              </div>
              <span>Connect your devices</span>
            </div>
          </Option>, 
          <Option key = 'Device and Data Management'>
            <div style={{display: "flex"}}>
              <div className='circle1 redg1'>
                <div className='circle redg'/>
              </div>
              <span>Device and Data Management</span>
            </div>
          </Option>,
          <Option key = '3-month free trial'>
            <div style={{display: "flex"}}>
              <div className='circle1 redg1'>
                <div className='circle redg'/>
              </div>
              <span>3-month free trial</span>
            </div>
          </Option>]

  function selectHandleChange1(value) {
    console.log(`${value}`);
    setSelect1State(value)
  }


  function selectHandleChange2(value) {
    console.log(`${value}`);
    setSelect2State(value)
  }

  const handleOk = () => {
    setShowModel(false);
  };

  const handleCancel = () => {
    setShowModel(false);
  };

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='section-title'>
                <h2>Free trial</h2>
                <p>
                  Leave your contact information to get full featured product trials immediately
                </p>
              </div>
              <Form id='sentMessage' name='sentMessage' onFinish={handleSubmit}>
                <div className='row'>
                  <div className='col-md-5'>
                    <Form.Item
                      name='first name'
                      rules={[{ required: true, message:'*Please enter your name' }]}
                    >
                      <Input value={name} id='name' name='name' placeholder='First name' className='form-control' onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                      name='email address'
                      rules={[{ required: true, type: 'email', message:'*Please enter a valid email address' }]}
                    >
                      <Input value={email} id="email" name="email" placeholder='Email address' className='form-control' onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                      name='phone number'
                      rules={[{ required: true, message:'*Please enter your mobile phone number'}]}
                    >
                      <Input value={phone} id="phone" name="phone" placeholder='Mobile phone number' className='form-control' onChange={handleChange}/> 
                    </Form.Item>
                    <Form.Item
                      name='company name'
                      rules={[{ required: true, message:'*Please enter your company name' }]}
                    >
                      <Input value={companyName} onChange={handleChange} id='companyName' name="companyName" placeholder='Company name' className='form-control'/> 
                    </Form.Item>
                    <Form.Item
                      name='job title'
                      rules={[{ required: true, message:'*Please enter your job title' }]}
                    >
                      <Input value={jobTitle} onChange={handleChange} id="jobTitle" name='jobTitle' placeholder='Job title' className='form-control'/> 
                    </Form.Item>
                  </div>
                  <div className='col-md-7'>
                    <Form.Item
                      name='organization fields'
                      rules={[{ required: true, message:'*Please select one' }]}
                    >
                      <Select id='organizationFields' name='organizationFields' size='default' style={{width: "100%"}} onChange={selectHandleChange1} placeholder="Organization fields">
                        {select1}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='services looking for'
                      rules={[{ required: true, message:'*Please select all that apply' }]}
                    >
                      <Select id='services' name='services' mode="multiple" size='default' 
                      style={{width: "100%"}} onChange={selectHandleChange2} 
                      placeholder="Services you are looking for"
                      showArrow={true} menuItemSelectedIcon={null} showSearch={false}>
                        {select2}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='optional'
                      rules={[{ required: false }]}
                    >
                      <Input.TextArea
                        id='message'
                        name='message'
                        value={message}
                        onChange={handleChange}
                        className='form-control cancel-resize'
                        rows='6'
                        placeholder='(Optional) To help us better understand your needs, please tell us more about what you want to achieve through connection.'
                      ></Input.TextArea>
                      </Form.Item>
                  </div>
                </div>
                <Form.Item>
                  <div className='center-btn'>
                    <button type='submit' className='btn btn-custom btn-lg center' htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <p style={{fontSize: "1.125rem", color:"#F8FBFD", opacity:"0.7"}}>
                  Contact us：
                  <a style={{color:"#F8FBFD", textDecoration: "underline"}}
                    href="mailto:hello@mio-labs.com">
                      info@mio-labs.com
                  </a>
                  <br/>
                  <span style={{display: "block", marginTop: "0.5rem"}}>
                    #1023, ZGC Innovation Center, 4500 Great America Pkwy,<br/>
                    Santa Clara, CA 95054.<br/>
                    Tel: +1-646-838-4660
                  </span>
                </p>
              </div>
              <div className='col-md-6 col-sm-12 contact-logo'>
                <Row style={{display: "flex", justifyContent: "flex-end"}}>
                  { !Spinoff && props.data &&
                    <Link to='/about'>
                      <img src={props.data.logo1}/>
                    </Link>
                  }
                  { Spinoff && props.data &&
                    <Link to='/'>
                      <img src={props.data.logo2}/>
                    </Link>
                  }
                </Row>
                <Row style={{display: "flex", justifyContent: "flex-end"}}>
                  <div style={{fontSize: "1.125rem", color:"#F8FBFD;", opacity:"0.7"}}>
                    <a style={{color:"#F8FBFD"}}>Terms of use</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{color:"#F8FBFD"}}>Privacy policy</a>
                  </div>
                </Row>
              </div>
            </div>
        </div>
      </div>
      <Modal title="Thank you！" visible={showModel} onOk={handleOk} onCancel={handleCancel} centered cancelText='close'>
        <span>We have received your request. One of our expert team members will get back in touch with you within 48 hours.</span>
      </Modal>
    </div>
  )
}
